import React, { useState, useRef } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TicketLogModal from "../TicketLogModal";

const TicketListOptionsMenu = ({ ticket, anchorEl, menuOpen, handleClose, menuPosition }) => {
	const [ticketLogModalOpen, setTicketLogModalOpen] = useState(false);
	const isMounted = useRef(true);

	const handleOpenTicketLogModal = () => {
		setTicketLogModalOpen(true);
		handleClose();
	};

	const handleCloseTicketLogModal = () => {
		if (isMounted.current) {
			setTicketLogModalOpen(false);
		}
	};

	return (
		<React.Fragment>
			<Menu
				anchorReference="anchorPosition"
				anchorPosition={{ top: menuPosition.top, left: menuPosition.left }}
				open={menuOpen}
				onClose={handleClose}
			>
				<MenuItem onClick={handleOpenTicketLogModal}>Histórico do Ticket</MenuItem>
			</Menu>
			{ticket && (
				<TicketLogModal
					modalOpen={ticketLogModalOpen}
					ticketid={ticket.id}
					onClose={handleCloseTicketLogModal}
				/>
			)}
		</React.Fragment>
	);
};

export default TicketListOptionsMenu;
