import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const SelecionaFilaModal = ({ selecionarFilaModalOpen, onClose, contactId, userId, status }) => {
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [selectedQueue, setSelectedQueue] = useState('');

	const handleClose = () => {
		onClose();
		setSelectedQueue('');
	};

	const handleNewChat = async () => {
		if (!selectedQueue) return;

		setLoading(true);
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId,
				userId,
				status,
				queueId: selectedQueue,
			});
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		} finally {
			setLoading(false);
			handleClose();
		}
	};

	return (
		<Dialog open={selecionarFilaModalOpen} onClose={handleClose}>
			<DialogTitle id="form-dialog-title">
				{i18n.t("newTicketModal.title")}
			</DialogTitle>
			<DialogContent dividers>
				<FormControl variant="outlined" style={{ width: 300, marginTop: 20 }}>
					<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
					<Select
						value={selectedQueue}
						onChange={(e) => setSelectedQueue(e.target.value)}
						label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
						required
					>
						<MenuItem value={''}>&nbsp;</MenuItem>
						{user.queues.map((queue) => (
							<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
						))}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					color="secondary"
					disabled={loading}
					variant="outlined"
				>
					{i18n.t("Cancelar")}
				</Button>
				<ButtonWithSpinner
					variant="contained"
					type="button"
					disabled={!selectedQueue}
					onClick={handleNewChat}
					color="primary"
					loading={loading}
				>
					{i18n.t("Confirma")}
				</ButtonWithSpinner>
			</DialogActions>
		</Dialog>
	);
};

export default SelecionaFilaModal;
