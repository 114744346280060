import React, { createContext, useState, useContext } from "react";

const TicketsContext = createContext();

export const TicketsProvider = ({ children }) => {
  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  return (
    <TicketsContext.Provider value={{ openCount, setOpenCount, pendingCount, setPendingCount }}>
      {children}
    </TicketsContext.Provider>
  );
};

export const useTickets = () => useContext(TicketsContext);
