import React, { useContext } from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const Route = ({ component: Component, isPrivate = false, layout: Layout, ...rest }) => {
  const { isAuth, loading } = useContext(AuthContext);

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <RouterRoute
      {...rest}
      render={(props) => {
        const urlParams = new URLSearchParams(props.location.search);
        const ticketcrm = urlParams.get("ticketcrm");

        const redirectUrl = ticketcrm ? `/ticketcrm/${ticketcrm}` : (props.location.state?.from?.pathname || "/");

        if (isPrivate && !isAuth) {
          return (
            <Redirect
              to={{ pathname: "/inicializarwhatsapp", state: { from: props.location } }}
            />
          );
        }

        if (!isPrivate && isAuth) {
          return <Redirect to={redirectUrl} />;
        }

        return Layout ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default Route;
