import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	ticketsListWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},
	ticketsList: {
		flex: 1,
		overflowY: "scroll",
		...theme.scrollbarStyles,
		borderTop: "2px solid rgba(0, 0, 0, 0.12)",
	},
	ticketsListHeader: {
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	ticketsCount: {
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},
	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},
	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},
	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const reducer = (state, action) => {
	switch (action.type) {
		case "RESET":
			return [];
		case "LOAD_TICKETS":
			return action.payload;
		case "RESET_UNREAD":
			return state.map(ticket =>
				ticket.id === action.payload
					? { ...ticket, unreadMessages: 0 }
					: ticket
			);
		case "UPDATE_TICKET":
			return state.some(t => t.id === action.payload.id)
				? state.map(ticket =>
					ticket.id === action.payload.id ? action.payload : ticket
				)
				: [action.payload, ...state];
		case "UPDATE_TICKET_UNREAD_MESSAGES":
			return state.some(t => t.id === action.payload.id)
				? [
					action.payload,
					...state.filter(ticket => ticket.id !== action.payload.id)
				]
				: [action.payload, ...state];
		case "UPDATE_TICKET_CONTACT":
			return state.map(ticket =>
				ticket.contactId === action.payload.id
					? { ...ticket, contact: action.payload }
					: ticket
			);
		case "DELETE_TICKET":
			return state.filter(ticket => ticket.id !== action.payload);
		default:
			return state;
	}
};

const TicketsList = (props) => {
	const { status, searchParam, showAll, selectedQueueIds, updateCount, style } = props;
	const classes = useStyles();
	const [pageNumber, setPageNumber] = useState(1);
	const [ticketsList, dispatch] = useReducer(reducer, []);
	const { user } = useContext(AuthContext);
	const [ocultaTickets, setOcultaTickets] = useState("");

	const idgrupoempresa = user.idgrupoempresa;

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [status, searchParam, dispatch, showAll, selectedQueueIds]);

	const { tickets, hasMore, loading } = useTickets({
		pageNumber,
		searchParam,
		status,
		showAll,
		queueIds: JSON.stringify(selectedQueueIds),
		idgrupoempresa: idgrupoempresa,
	});

	useEffect(() => {
		if (!status && !searchParam) return;
		dispatch({
			type: "LOAD_TICKETS",
			payload: tickets,
		});
	}, [tickets, status, searchParam]);

	useEffect(() => {
		const socket = openSocket();

		const shouldUpdateTicket = ticket => !searchParam &&
			(!ticket.userId || ticket.userId === user?.id || showAll) &&
			(!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

		const notBelongsToUserQueues = ticket =>
			ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

		const notBelongsToUserIdGrupoempresa = ticket =>
			ticket.idgrupoempresa !== idgrupoempresa;

		socket.on("connect", () => {
			if (idgrupoempresa) {
				socket.emit("joinGroup", idgrupoempresa);
			}

			if (status) {
				socket.emit("joinTickets", status);
			} else {
				socket.emit("joinNotification");
			}
		});

		socket.on("ticket", data => {
			if (data.action === "updateUnread") {
				dispatch({
					type: "RESET_UNREAD",
					payload: data.ticketId,
				});
			}

			if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET",
					payload: data.ticket,
				});
			}

			if (data.action === "update" && (notBelongsToUserQueues(data.ticket) || notBelongsToUserIdGrupoempresa(data.ticket))) {
				dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
			}
		});

		socket.on("appMessage", data => {
			if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET_UNREAD_MESSAGES",
					payload: data.ticket,
				});
			}
		});

		socket.on("contact", data => {
			if (data.action === "update") {
				dispatch({
					type: "UPDATE_TICKET_CONTACT",
					payload: data.contact,
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [status, searchParam, showAll, user, selectedQueueIds]);

	useEffect(() => {
		if (typeof updateCount === "function") {
			updateCount(ticketsList.length);
		}
	}, [ticketsList]);

	useEffect(() => {
		const fetchOcultaTickets = async () => {
			try {
				const response = await api.get(`/paramempresa/`);
				setOcultaTickets(response.data.parametros.st_oculta_tickets_aguardando);

			} catch (err) {
				toastError(err);
			}
		}

		fetchOcultaTickets();
	}, [user.id]);

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			e.currentTarget.scrollTop = scrollTop - 100;
			loadMore();
		}
	};

	const sortTickets = (tickets, status) => {
		const pendingTickets = tickets.filter(ticket => ticket.status === 'pending');
		const sortedPendingTickets = pendingTickets.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

		const otherTickets = tickets.filter(ticket => ticket.status !== 'pending');
		const sortedOtherTickets = otherTickets.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

		if (status === 'pending') {
			if (ocultaTickets === "S" && user.profile !== "admin") {
				return sortedPendingTickets.slice(0, 1);
			} else {
				return sortedPendingTickets;
			}
		} else {
			return sortedOtherTickets;
		}
	};

	const sortedTickets = sortTickets(ticketsList, status);

	return (
		<Paper className={classes.ticketsListWrapper} style={style}>
			<Paper
				square
				name="closed"
				elevation={0}
				className={classes.ticketsList}
				onScroll={handleScroll}
			>
				<List style={{ paddingTop: 0 }}>
					{sortedTickets.length === 0 && !loading ? (
						<div className={classes.noTicketsDiv}>
							<span className={classes.noTicketsTitle}>
								{i18n.t("ticketsList.noTicketsTitle")}
							</span>
							<p className={classes.noTicketsText}>
								{i18n.t("ticketsList.noTicketsMessage")}
							</p>
						</div>
					) : (
						<>
							{sortedTickets.map(ticket => ticket && (
								<TicketListItem ticket={ticket} key={ticket.id} />
							))}
						</>
					)}
					{loading && <TicketsListSkeleton />}
				</List>
			</Paper>
		</Paper>
	);
};

export default TicketsList;
