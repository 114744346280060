import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	makeStyles,
	Button
} from "@material-ui/core";
import { format } from 'date-fns';
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	dialogPaper: {
		minWidth: 700,
	},
	tableContainer: {
		width: "100%",
	},
	table: {
		width: "100%",
		tableLayout: "auto",
	},
	closeButton: {
		backgroundColor: theme.palette.grey[700],
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.grey[400],
		},
	},
	tableHead: {
		backgroundColor: theme.palette.grey[400],
	},
	tableCellHeader: {
		color: theme.palette.grey[800],
		fontWeight: "bold",
		fontSize: 18,
		textAlign: "center",
		verticalAlign: "middle",
	},
	tableRow: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
		"&:last-child td, &:last-child th": {
			border: 0, // Remove a borda inferior da última linha
		},
	},
	tableCellBody: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		textAlign: "center",
		verticalAlign: "middle",
	},
	dialogTitle: {
		color: theme.palette.grey[700],
		fontSize: 20,
		fontWeight: "bold",
	}
}));

const TicketLogModal = ({ modalOpen, ticketid, onClose }) => {
	const classes = useStyles();
	const [ticketLogData, setTicketLogData] = useState([]);

	useEffect(() => {
		const fetchTicketLogData = async () => {
			if (modalOpen && ticketid) {
				try {
					const response = await api.get(`/ticketlog/${ticketid}`);
					if (response.data.length > 0) {
						setTicketLogData(response.data);
					} else {
						setTicketLogData([]);
					}
				} catch (err) {
					setTicketLogData([]);
				}
			}
		};
		fetchTicketLogData();
	}, [modalOpen, ticketid]);

	const handleClose = () => {
		onClose();
		setTicketLogData([]);
	};

	const color = (sn_acao) => {
		switch (sn_acao) {
			case "CONCLUIR":
				return { color: "green" };
			case "ACEITAR":
				return { color: "blue" };
			case "RETORNAR":
				return { color: "yellow" };
			case "REABRIR":
				return { color: "blueviolet" };
			case "TRANSFERIR":
				return { color: "orange" };
			case "ABERTO":
				return { color: "lightgreen" };
			default:
				return { color: "darkgrey" };
		}
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} classes={{ paper: classes.dialogPaper }} scroll="paper">
			<DialogTitle className={classes.dialogTitle}>
				Log Ticket
			</DialogTitle>
			<DialogContent>
				<TableContainer component={Paper} className={classes.tableContainer}>
					<Table className={classes.table}>
						<TableHead className={classes.tableHead}>
							<TableRow>
								<TableCell className={classes.tableCellHeader}>Ação</TableCell>
								<TableCell className={classes.tableCellHeader}>Usuário</TableCell>
								<TableCell className={classes.tableCellHeader}>Data</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{ticketLogData.length > 0 ? (
								ticketLogData.map((log, index) => (
									<TableRow key={index} className={classes.tableRow}>
										<TableCell style={color(log.sn_acao)} className={classes.tableCellBody}>{log.sn_acao}</TableCell>
										<TableCell style={color(log.sn_acao)} className={classes.tableCellBody}>{!log.user ? "-" : log.user.name}</TableCell>
										<TableCell style={color(log.sn_acao)} className={classes.tableCellBody}>{format(new Date(log.dt_data_alteracao), 'dd/MM/yyyy - HH:mm:ss')}</TableCell>
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={3} className={classes.tableCellBody}>Nenhum Histórico disponível</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button className={classes.closeButton} onClick={handleClose} color="primary">
					Fechar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default TicketLogModal;
